
div.product-item { 
    display: flex;
    position: relative;
    margin-bottom: 24px;
    border: 1px solid #ccc;
    line-height: 0;
    border-radius: 16px;
    overflow: clip;
}

div.product-item * { 
    line-height: initial;
}

.product-item .product-item-image {
    
}

.product-item .product-item-image a > .blank-image-icon { 
    height: 100%;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--light-bg-color);
}

.product-item .product-item-image a > .blank-image-icon > svg { 
    width: 48px;
    height: 48px;
    color: var(--paylani-blue-faded);
}

.product-item-image,
.product-item-image > a > img {
    vertical-align: middle;
    width: 200px;
}

div.product-item > div.product-item-details { 
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0;
}

div.product-item > div.product-item-details > * { 

}

div.product-item > div.product-item-details > p { 
    margin-bottom: 0;
    margin-top: 12px;
}

div.product-item > div.product-item-details > p.no-description { 
    
}

div.product-item > div.product-item-details > *:last-child { 
    margin-top: auto;
    margin-bottom: 0;
    padding-bottom: 0;
}

div.product-item > div.product-item-details > h4 { 
    font-weight: bold;
    margin-bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

div.product-item > div.product-item-details > h4 > span.delete {
    font-weight: normal;
    margin-left: auto;
}

div.product-item .hover-only {
    opacity: 0;
}

div.product-item:hover .hover-only {
    opacity: 1.0;
}

div.product-item > div.product-item-details > div.pricing { 
    display: flex;
    width: 100%;
    margin-top: 12px;
    justify-content: space-between;
    padding: 8px;
    background-color: var(--yellow-faded);
    border: var(--yellow-faded) 1px solid;
    border-radius: 6px;
}

div.product-item > div.product-item-details > div.pricing strong { 
    color: var(--header-green);
}

div.product-item > div.product-item-details > div.pricing > span { 
    min-width: 180px;
}

div.catalogs {
    display: block;
    margin-bottom: 12px;
    width: 100%;
}

div.catalogs > div.catalog-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    border-bottom: var(--border-color) 1px solid;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

div.catalogs > div.catalog-item > span { 
    white-space: nowrap;
    text-align: right;
}

div.catalogs > div.catalog-item > span:first-child { 
    width: 300px;
    text-align: left;
}

div.catalogs > div.catalog-item > span:last-child { 
    margin-left: auto;
}

div.catalog-form { 
    width: 500px;
}
div.catalog-form > .form-note { 
    margin-bottom: 16px;
}

div.catalog-location-list { 
    font-size: 13px;
    opacity: 0.7;
}

div.catalog-location-list:hover { 
    opacity: 1.0;
}

div.catalog-location-list > strong { 
    display: block;
    padding-bottom: 8px;
    padding-top: 8px;
    font-weight: normal;
}

div.catalog-location-list > span { 
    display: inline-block;
    margin: 0 8px 5px 0;
    background-color: var(--light-bg-color);
    padding: 4px 7px;
    border-radius: 5px;
}