
#sale-details-main .details-body #sale-details-content { 
    max-width: 900px;
}

#sale-details-main .details-body #sale-details-content span { 
}

#sale-details-main .details-body #sale-details-content .sale-total-container { 
    float: right;
    display: flex;
    align-items: center;
}

#sale-details-main .details-body #sale-details-content .sale-total-container span { 
    display: inline-block;
}

#sale-details-main .details-body #sale-details-content .sale-total-container span:first-child { 
    margin-right: 12px;
    padding-top: 16px;
}

#sale-details-main .details-body #sale-details-content .sale-total-container span.amount {
    font-size: 42px;
}

.sales-cart-items {
    margin-bottom: 32px;
}

.sales table tr td {
    font-size: 14px;
}

.sales table tr td div.sale-owner {
    display: block;
}

.sales table tr td div.sale-owner > div {
    display: block;
}

.sales table tr td div.sale-owner > div:first-child {
    padding-bottom: 3px;
}

.sales table tr td div.sale-owner > div:last-child a {
    background-color: #CCCCCC66;
    font-size: 11px;
    padding: 4px 8px 4px 1px;
    border-radius: 6px;
    margin-top: 3px;
    margin-bottom: 0;
    display: inline-block;
}

.sales table tr td div.sale-owner > div:last-child a:hover {
    text-decoration: underline;
}

.sales table tr td.status,
.sales table tr td.desc {
}

.sales table tr td svg {
    min-width: 24px;
    margin-right: 4px;
}

.sales table tr td.failed svg {
    color: var(--error-red);
}

.sales table tr td.completed,
.sales table tr td.completed svg {
    color: var(--header-green);
}

.sales table tr td.completed {
    font-size: 13px;
}

.payment-line-item { 
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: var(--light-border-color) 1px dashed;
}

.payment-line-item:last-child { 
    border-bottom-width: 0;
}

.payment-item { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.payment-item.success-true {
    
}

.payment-item.success-false {
    
}

.payment-item > div { 
    
}

.payment-item > div > div.payment-type {
    background-color: #EEE;
    border: #DDD 1px solid;
    display: inline-block;
    border-radius: 10px;
    min-width: 200px;
    min-height: 90px;
    margin-right: 32px;
}

.payment-item > div > div.payment-type > div { 
    padding: 12px;
    font-size: 13px;
}

.payment-item span.credit-card svg {
    display: block;
    max-height: 38px;
    margin-bottom: 6px;
}

.payment-item > div.info { 
    /* display: flex; */
    flex: max-content;
}

.payment-item > div ul { 
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
}

.payment-item > div ul li { 
    display: block;
    font-size: 14px;
    padding: 2px 0;
}

.payment-item > div.info ul li.payment-amount { 
    font-size: 28px;
    margin-top: 0;
}

.payment-item span {
    display: inline-block;
}

.payment-item > div.info .error { 
    color: var(--failed-line-item-color);
    font-size: 14px;
    width: 340px;
}

.payment-item > div.info .error strong {
    font-weight: normal;
}

.payment-item > div.info ul li.message { 
    font-size: 12px;
    padding-top: 3px;
}

.payment-item.success-false > div.info ul li.message { 
    color: var(--failed-line-item-color);
}

.payment-item > div.auth-info { 
    margin-left: 32px;
}

.payment-item > div.auth-info ul {
    text-align: right;
}

.payment-line-item .payment-log { 
    overflow: scroll;
    white-space: pre-wrap;
    background-color: #FFE;
    padding: 12px;
    margin-top: 16px;
    border-radius: 24px;
}

.payment-line-item .payment-log.hidden {
    display: none;
}

.payment-line-item .payment-log pre { 
    white-space: pre-wrap;    
}

h2 .panel span.customer-name,
h2 .panel span.sale-status {
    width: 100%;
}

h2 .panel span.sale-status { 
    margin-left: 24px;
    display: inline-block;
}

h2 .panel span.sale-status > label { 
    border-radius: 8px;
    color: white;
    background-color: var(--border-color);
    font-size: 13px;
    padding: 6px 12px;
    display: inline-block;
}

h2 .panel span.sale-status.status-1 > label { 
    background-color: var(--header-green);
    opacity: 0.55;
}

h2 .panel span.sale-status.status-1:hover > label,
h2 .panel span.sale-status.status-3:hover > label { 
    opacity: 1.0;
}

h2 .panel span.sale-status.status-3 > label {
    background-color: var(--error-red);
    opacity: 0.55;
}


h2 .panel span.sale-number {
    white-space: nowrap;
    margin-right: 12px;
}

.date-activity.sale {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    align-content: center;
    margin-bottom: 12px;
    padding: 16px 24px;
    background-color: var(--light-bg-color);
    border-radius: 12px;
}

.date-activity.sale > span { 
    font-size: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: clip;
}

.date-activity.sale > span { 
    margin-right: 0;
    background-color: white;
}

#user-sale-details-location {
}
