#user-details-body { 
}

#user-details-body > div {
    padding-top: 24px;
}

#user-details-body ul { 
    padding: 0;
    margin: 0;
    display: block;
    list-style: none;
}

#user-details-body ul li {
    padding: 8px 0;
}

#user-submenu div.image {
    text-align: center;
    padding-top: 24px;
    margin-right: 12px;
    margin-left: 12px;
}

#user-profile-image > div.image { 
    padding-top: 0;
}

#user-profile-image > div.image-uploader { 
    margin-top: 8px !important;
}

#user-submenu div.profile { 
}

#user-submenu div.profile #user-profile-name {
    padding-top: 8px;
    padding-bottom: 4px;
}

#user-profile-image .image-uploader div.blank-image-icon,
#user-submenu div.image span.image { 
    display: inline-block;
    border-radius: 100px;
    align-content: center;
    align-items: center;
    text-align: center;
    min-width: 128px;
    height: 128px;
    
    background-color: var(--paylani-blue);
    border: rgba(255, 255, 255, 0.6) 8px solid;
}

#user-submenu div.image img {
    width: 128px;
    height: 128px;
    border-radius: 100px;
    border: rgba(0, 0, 0, 0.1) 8px solid;
}

#user-submenu div.image svg { 
    height: 64px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.95);
    align-self: center;
    display: inline-block;
}

#user-profile-image .image-uploader div.blank-image-icon { 
    display: flex;
    align-items: center;
}

#user-profile-image #user-submenu .image a > img {
}

#user-profile-image .image-uploader div.blank-image-icon svg { 
    max-width: 42px;
    margin: 0;
    opacity: 0.8;
}

table tr.current td,
table tr.current td a { 
    color: var(--header-green);
}



#user-activity-body table tr { 
    color: var(--text-color);
}

#user-activity-body table tr:hover {
    color: var(--darkest);
}

#user-activity-body table tr td span.icon { 
    width: 32px;
    display: inline-block;
}

#user-activity-body table tr th.activity-text-td,
#user-activity-body table tr td.activity-text-td {
    width: 78%;
}

#user-activity-body table tr th.activity-date-td,
#user-activity-body table tr td.activity-date-td {
    width: 30%;
}

.date-activity span #user-created-date {
    
}

#merchant-search-container { 
    
}

#merchant-search-results-container { 
    overflow: unset;
    display: block;
    position: relative;
}

#merchant-search-results-container #merchant-search-results { 
    position: absolute;
    background-color: white;
    border: var(--light-border-color) 1px solid;
    max-height: 500px;
    overflow: auto;
    padding: 12px;
    z-index: 24;
}

#merchant-search-results-container #merchant-search-results ul {
    list-style: none;
    padding: 0;
    margin: 0 16px 0 0;
    display: block;
    font-size: 13px;
}

#merchant-search-results-container #merchant-search-results ul li { 
    margin-bottom: 1px;
}

#merchant-search-results-container #merchant-search-results ul li a { 
    padding: 8px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
}

#merchant-search-results-container #merchant-search-results ul li.selected a span:first-child { 
    display: inline-block;
    margin-right: 12px;
}

#merchant-search-results-container #merchant-search-results ul li a svg {
    color: var(--header-green);
    opacity: 0.0;
    margin-left: 12px;
}

#merchant-search-results-container #merchant-search-results ul li.selected a svg { 
    opacity: unset;
}

#merchant-search-results-container #merchant-search-results ul li a:hover { 
    background-color: var(--paylani-blue);
    color: white;
}

#merchant-search-results-container #merchant-search-results ul li a:hover svg { 
    color: white;
}

label.username { 
    color: var(--header-green);
}

.user-badge { 
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    gap: 16px;
    background-color: var(--light-bg-color);
    padding: 12px;
    border-radius: 12px;
}

.user-badge > span { 
    display: inline-block;
    white-space: nowrap;
}

.user-badge > span:first-child { 
    /* */
}

.user-badge > span:first-child img {
    max-width: 48px;
    max-height: 48px;
    border-radius: 50%;
}

.user-badge > span:first-child svg {
    height: 24px;
    width: 24px;
    padding: 8px;
    border-radius: 50%;
    background-color: var(--paylani-blue);
    color: var(--light-bg-color);
}


.user-badge > span:last-child {
    /* */
}

.user-badge > span:last-child > strong {
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
}

.user-badge > span:last-child > a {
    display: block;
    font-size: 14px;
}


