.expandable-section { 
    width: 100%;
}

.expandable-section.hidden { 
    
}

.expandable-section > .expandable-section {

}

.expandable-section.hidden > div.expandable-section {
    display: none;
}

.expandable-section h3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.expandable-section.hidden h3 {
    border: var(--border-color) 1px solid;
    background-color: #EEE;
    padding-left: 12px;
    padding-right: 12px;
}

.expandable-section h3 > span {
    display: inline-block;
}

.expandable-section h3 > *:last-child { 
    margin-left: auto;
    padding-right: 0;
}

.expandable-section h3 > *:last-child > a > svg { 
    margin-right: 0;
}
