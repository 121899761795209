h2 span.filter, h3 span.filter {
    background-color: var(--light-box-color);
}

h3 span.filter > a > label, h2 span.filter > a > label {
    color: var(--text-color);
}

h3 span.filter > span.input > a svg, h2 span.filter > span.input > a svg {
    color: var(--light-border-color);
}

h3 span.filter input, h2 span.filter input {
    color: var(--text-color);
    border-color: var(--input-border-color); /* Color */;
    background-color: var(--input-background-color);
}
