.virtual-terminal { 
    margin-bottom: 164px;
}

.virtual-terminal > .form #amount {
    font-size: 42px;
    max-width: 280px;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    padding-left: 16px;
}

.virtual-terminal > .form #amount::placeholder { 
    color: var(--light-border-color); 
}

.amount-container {
    position: relative;
}

.amount-container > .symbol {
    color: var(--border-color);
    position: absolute;
    left: 0;
    top: 16px;
}

span.virtual-image { 
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: var(--light-border-color);
    font-size: 22px;
    border: var(--border-color) 3px solid;
    overflow: hidden;
}

span.virtual-image img {
    width: 42px;
    height: 42px;
    object-fit: cover;
}

h3.payment-selector { 
    display: flex;
    justify-content: space-between;
}

h3.payment-selector > a > svg { 
    color: var(--border-color);
}

h3.payment-selector > a:hover > svg {
    color: var(--paylani-blue);
}

h3.payment-selector > a.selected > svg { 
    color: var(--paylani-blue);
}

h3.payment-selector > span:first-child { 
    display: inline-block;
    margin-right: auto;
}

.virtual-terminal-panel { 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 48px;
}

.virtual-terminal-panel > div { 
    
}

.virtual-terminal-panel > div:last-child { 
    flex: 4;
}

.virtual-terminal-panel .customer-sale-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    border-radius: 12px;
    background-color: #EEE;
    border: #CCC 1px solid;
}

.virtual-terminal-panel .customer-sale-info > .customer-image { 
    margin-right: 24px;
}

.virtual-terminal-panel .customer-sale-info ul { 
    display: block;
    padding: 0;
    list-style: none;
}

.virtual-terminal-panel .customer-sale-info ul > li { 
    display: block;
    list-style: none;
    padding-bottom: 8px;
}

.virtual-terminal-panel .customer-sale-info ul > li:first-child { 
    font-weight: bold;
}
