:root {
  --bg-color: #FFF;
  --code-background-color: #F0F0F0;
  --light-bg-color: #f3f3f3;
  --link-color: #0032A0;
  --link-hover-color: blue;
  --dark-background-color: #EEE;

  --light-link-color: #FFFFFFCC;
  --light-link-hover-color: #FFF;
  --light-icon-color: #FFFFFFCC;

  --add-new-color: #AAA;
  --add-new-hover-color: blue;
  --add-new-border-color: #DDD;
  
  --text-color: #555;
  --text-color-faded: #999;
  --light-text-color: #FFF;
  --paragraph-text-color: #555;

  --col-color: #012175;
  --col-link-color: white;

  --svg-color: #000000AA;
  --light-svg-color: #FFFFFFAA;
  
  --dark-box-color: #012175;
  --medium-box-color: #CCC;
  --light-box-color: #F6F6F6;
  
  --selected-text-color: black;
  --selected-bg-color: #0032A0;
  --selected-dark-text-color: white;
  --submenu-item-selected-color: #DDD;
  
  --darkest: #222;
  --paylani-blue: #0032A0;
  --paylani-blue-faded: #0032A088;

  --light-border-color: #EEE;  
  --medium-border-color: #DDD;
  --input-border-color: #DDD;
  
  --header-green: green;
  --header-blue: #003280;
  
  --paylani-green: #00fe35;
  --paylani-green-faded: #3BD23D;
  --visa-color: #AAA9FF88;
  --mastercard-color: #EED02D88;
  --discover-color: #CE702D88;
  --amex-color: #6666FF77;
  --yellow: #CCDD88FF;
  --yellow-faded: #CCCC1118;
  
  --worldpay-color: rgba(255, 210, 120, 0.8);
  --fiserv-color: rgba(255, 110, 110, 0.7);

  --button-color: #000088;
  --button-text-color: white;
  --button-hover-color: #1f8142;

  --action-button-color: rgba(0, 0, 0, 0.1);
  --action-button-text-color: #FFF;
  
  --delete-red: maroon;
  --failed-line-item-color: rgb(180, 50, 50);
  --error-red: rgb(200, 50, 50);
  --warning-red: rgb(255, 150, 150);
  --disabled-text-color: rgba(255, 255, 255, 0.35);

  --border-color: rgba(0, 0, 0, 0.2);
  --approved-color: #CCDD88CC;
  --approved-color-faded: #CCDD8855;

  --dialog-background-color: white;
  --dialog-button-color: #0032A0;
  
  --image-size: 48px;
  --input-background-color: #EEE;
}

*,
*::before,
*::after { 
    box-sizing: border-box;
}

.nav-tooltip { 
  
}

.nav-tooltip-placement { 
  background-color: #000000AD !important;
  color: white !important;
  font-size: 14px !important;
}

.nav-tooltip ul {
  list-style: none;
  display: block;
  padding: 12px;
  margin: 0;
}

.nav-tooltip ul > li {
  margin-bottom: 8px;
  min-width: 190px;
}

.nav-tooltip ul > li:last-child {
  margin-bottom: 0;
}

.nav-tooltip ul > li.header {
  color: white;
  padding-bottom: 8px;
  padding-top: 24px;
  margin-bottom: 12px;
  border-bottom: #FFFFFF44 1px dashed;
}

.nav-tooltip ul > li.header:first-child {
  padding-top: 0;
}

.nav-tooltip ul > li > a {
  color: white;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #FFFFFF22;
  cursor: pointer !important;
  border: transparent 1px solid;
}

.nav-tooltip ul > li > a img {
  width: 16px;
  height: 16px;
  object-fit: cover;
  margin-right: 8px;
}

.nav-tooltip ul > li > a:hover {
  color: var(--yellow);
  background-color: var(--dark-box-color);
}

.nav-tooltip ul > li > a > span.nav-spinner:last-child svg { 
  color: white !important;
  animation: spin 0.75s linear infinite;
}

.nav-tooltip ul > li > a > span.nav-spinner:last-child { 
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
}

.nav-tooltip ul > li#cardsync-nav > a {
  background-color: #0c1324 !important;
  border-color: #293040;
}

.nav-tooltip ul > li#cardsync-nav > a:hover {
  background-color: #001e6a !important;
}

.nav-tooltip ul > li#cardsync-nav > a img {
  opacity: 0.75 !important;
}

.nav-tooltip ul > li#cardsync-nav > a:hover img {
  opacity: 1.0 !important;
}

.nav-tooltip ul > li#evoke-nav > a { 
  background-color: #131a22 !important;
  border-color: #293040;
}

.MuiTooltip-tooltip.nav-tooltip-arrow > .MuiTooltip-arrow { 
  color: black !important;
}

html { 
  height: 100%;
  display: block;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  height: 100%;
  display: block;
}

canvas { 
  background-color: var(--light-box-color);
  border-radius: 12px;
}

a {
  color: var(--link-color);
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: var(--link-hover-color);
}

svg { 
  color: var(--svg-color);
}

#main {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-wrap: wrap;
  height: inherit;
  position: relative;
}

#main-column {
  background-color: var(--col-color);
  width: 260px;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
}

#main-column a svg {
  color: var(--col-link-color);
}

div.panel {
  display: block; 
}

#main-body {
  display: block;
  margin-left: 260px;
  /* background-color: rgba(255, 0, 0, 0.2); */
  width: calc(100vw - 260px); /* 100% of the view width, -minus the width of the left menu colum (260) */
  margin-top: 64px;
}

#snap-shot-percent {
  text-align: center;
}

.tiny {
  font-size: 11px;
}

.small {
  font-size: 12px;
}

.pies div.tiny,
.pies div.small { 
  margin-left: 8px;
  margin-right: 8px;
  color: var(--text-color);
}

#snap-shot-percent h1 { 
  margin-bottom: 2px;
}

#snap-shot-percent h1 span.percent-text { 
  color: var(--text-color);
  display: flex;
  justify-content: center;
}

#snap-shot-percent h1 span.percent-text span {
  font-size: 22px;
  letter-spacing: unset;
}

#snap-shot-percent h1 span.percent-text span:first-child {
  font-size: 100px;
  letter-spacing: -4px;
}

#snap-shot-percent h1 span.percent-text span.percent-display-decimal {
  font-size: 38px;
  padding-top: 20px;
  padding-left: 4px;
  font-weight: normal;
  opacity: 0.9;
}

.pies #snap-shot-percent.panel { 
  margin-left: unset;
}

#snap-shot-percent div {
  margin-left: 2px;
  margin-right: 2px;
}

span.tiny-tail { 
  font-size: 11px;
  display: inline-block;
}

span.small-tail {
  font-size: 22px;
  display: inline-block;
}

input[type="date"],
input[type="datetime-local"],
textarea {
  font-family: inherit;  /* 'Arial', 'Segoe UI', 'Roboto', serif;*/
  font-size: 14px;
}

h1, h2, h3, h4 { 
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 24px;
  position: relative;
  color: var(--darkest);
}

h2 span.padded-right { 
  display: inline-block;
  margin-right: 32px;
}

h1.flex,
h2.flex,
h3.flex,
h4.flex { 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex > .add { 
  margin-left: 48px;
}

h1.subtitle,
h2.subtitle,
h3.subtitle,
h4.subtitle { 
  padding-top: 24px;
}

h1 svg,
h2 svg,
h3 svg { 
  color: var(--svg-color);
  margin-right: 24px;
}

h3 span.refresh-button,
h2 span.refresh-button {
  font-size: 14px;
  font-weight: normal;
  margin-left: 48px;
  padding: 5px 12px;
  background-color: var(--action-button-color);
  border-radius: 8px;
  display: none;
}

h3 span.refresh-button.anchor,
h2 span.refresh-button.anchor { 
  display: inline-block;
}

h3 span.refresh-button a ,
h2 span.refresh-button a { 
  padding-top: 8px;
  padding-bottom: 8px;
  display: inline-block;
}

h3 span.refresh-button {
  /* Within an h3, we align right */
  position: absolute;
  right: 0;
}

h2 span.refresh-button.visible,
h3 span.refresh-button.visible,
h2:hover span.refresh-button,
h3:hover span.refresh-button {
  display: inline-block;
}

h3 span.refresh-button a svg,
h2 span.refresh-button a svg { 
  margin-right: 8px;
}

button.ui-action-button {
  display: inline-block;
  border-radius: 3px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  padding: 8px 24px;
  cursor: pointer;
  border-width: 0;
  font-size: 12px;
}

button.ui-action-button:hover { 
  transition-duration: 200ms;
  transition-property: background-color;
  background-color: var(--button-hover-color);
}

.form button { 
  display: inline-block;
  border-radius: 8px;
  background-color: var(--button-color);
  color: var(--button-text-color);
  padding: 18px 42px;
  cursor: pointer;
  border-width: 0;
  font-size: 16px;
}

.form button:hover {
  background-color: var(--button-hover-color);
}

.form .form-note { 
  font-size: 13px;
}

.form .form-error { 
  color: var(--error-red);
  font-size: 13px;
}

.form .form-note:empty,
.form .form-error:empty {
  font-size: 0;
  display: none;
}

.form-error .button.fixed .form-note { 
  font-size: 16px;
  background-color: #FEE;
  padding: 16px;
  margin-bottom: 24px;
  border-radius: 8px;
}

#logo { 
  text-align: center;
  border-bottom: #FFFFFF30 solid 1px;
  margin: 8px 24px 12px 24px;
  padding-bottom: 16px;
}

#logo a img { 
  opacity: 0.7;
}

#logo a:hover img {
  opacity: 1.0;
}

#root {
  height: inherit;
}

.error {
  color: var(--error-red);
  line-height: 24px;
}
  
.error-box { 
  padding-top:16px;
  color: var(--error-red);
  line-height: 24px;
}

.error-box strong { 
  color: var(--text-color);
}

#customer-support .body { 
  background-color: var(--dark-background-color);
}

ul.ul { 
  list-style: none;  
}

ul.ul,
#main-column ul {
  display: block;
  padding: 12px;
}

ul.ul li,

#main-column ul li {
  display: block;
  background-repeat: no-repeat;
  background-position: left center;
  margin-bottom: 24px;
}

#main-column ul li span { 
  display: inline-block;
}

#main-column ul li a { 
  display: block;
  padding: 16px;
  margin-left: 8px;
  border-radius: 5px;
  border-left: transparent 12px solid;
  color: var(--col-link-color);
  opacity: 0.8;
}

#main-column ul li.selected a {
  background-color: var(--selected-bg-color);
  color: var(--selected-dark-text-color);
  border-left-color: var(--paylani-green-faded);
  opacity: 0.9;
}

#main-column > ul li a:hover,
#main-column > ul li.selected a:hover {
  background-color: var(--selected-bg-color);
  border-left-color: var(--paylani-green);
  color: var(--selected-dark-text-color);
  opacity: 1.0;
  transition-duration: 200ms;
  transition-property: background-color;
}

#main-column ul li a svg { 
  margin-right: 12px;
  opacity: 0.5;
}

#main-column ul li a:hover svg {
  margin-right: 12px;
  opacity: 1.0;
}

#main-column ul#mini-menu li { 
  font-size: 12px;
}

#main-column ul#mini-menu li a {
  margin-left: 0;
}

#main-column ul#mini-menu li a svg {
  color: transparent;
}

#main-column ul#mini-menu li a:hover { 
  background-color: transparent;
  border-color: transparent;
  color: var(--header-green);
}

#main-column ul#mini-menu li a:hover svg {
  display: inline-block;
  color: var(--paylani-green);
}

#environment-div {
  position: absolute;
  font-size: 70px;
  font-weight: bold;
  z-index: 10;
  top: 24px;
  right: 300px;
  display: none;
  text-transform: capitalize;
  opacity: 0.2;
}

#environment-div > span { 
  display: block;
  margin-bottom: 1px;
}

#environment-div > span:first-child { 
  line-height: 0.8em;
}

#db-env {
  display: block;
  font-size: 14px;
  padding-left: 4px;
}

#environment-div.stage,
#environment-div.local,
#environment-div.development {
  display: unset !important;
}

#environment-div.stage {
  color: #A90;
}

#environment-div.local {
  color: red;
}

#environment-div.development {
  color: red;
}

hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: solid;
  border-width: 1px;
  color: rgba(255, 255, 255, 0.1);
}

#main-body.stage > div > h1,
#main-body.development > div > h1,
#main-body.local > div > h1 { 
  color: red;
}

#main-body-login { 
  width: 100%;
}

#my-header {
  display: flex;
  justify-content: stretch;
  align-items: flex-end;
  position: fixed;
  z-index: 12;
  right: 32px;
  top: 0;
  left: 308px;
  height: 110px;
  background-color: var(--bg-color);
  border-bottom: var(--border-color) 1px solid;
}

#my-header div.inner {
  margin-top: 32px;
  justify-content: space-between;
  background-color: var(--bg-color);
  min-width: 100%;
  width: 100%;
  padding-bottom: 16px;

  display: flex;
  align-items: flex-end;
}

#my-header div.inner span:first-child { 
  
}

#my-header .section {
  display: inline-block;
  background-color: var(--dark-box-color);
  border-radius: 128px;
  padding: 0;
  margin-left: 24px;
  height: 51px;
  color: var(--light-text-color);
}

#my-header .section a {
  color: var(--light-link-color);
}

#my-header .section a:hover {
  color: var(--light-link-hover-color);
}

#my-header .section .content { 
  display: flex;
}

#my-header .section .content.notifications { 
  width: 51px;
  height: 51px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}

#my-header .section .content.notifications .badge { 
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(190, 0, 0, 0.5);
  color: white;
  border: rgba(255, 255, 255, 0.5) 1px solid;
  padding: 7px 3px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;
  
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  filter: drop-shadow(0px 2px 4px black);
}

#my-header .section .content.notifications .badge .badge-content { 
  display: inline-block;
}

#my-header .section .content #notifications-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  /*background-color: rgba(0, 0, 0, 0.4);*/
  border-radius: 50%;
  margin: 3px;
}

#my-header ul {
  box-sizing: border-box;
  font-family: Archivo Narrow,Arial,Helvetica,sans-serif;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
}

#my-header div.inner > span {
}

#my-header div.inner > span:last-child {
}

#my-header div.inner #title-h1 h1 {
  margin-bottom: 0;
}

#my-header #my-header-account-panel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

#my-header > div.inner .section #my-profile-top-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image {
  display: inline-block;
  padding: 0;
  margin-right: 12px;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image img {
  border-radius: 70%;
  height: 42px;
  width: 42px;
  border: #FFFFFF44 2px solid;
}

#my-header > div.inner .section #my-profile-top-menu-item .default-image svg {
  border-radius: 70%;
  height: 24px;
  width: 24px;
  padding: 9px;
  background-color: black;
  color: var(--light-icon-color);
}

#my-header > div.inner ul li {
  font-size: 12px;
  padding-right: 16px;
  padding-top: 3px;
}

#my-header > div.inner ul li:first-child {
  font-size: 14px;
  color: white;
}

#my-header > div.inner > .section {
  display: inline-block;
  background-color: var(--dark-box-color);
  border-radius: 128px;
  padding: 0;
  margin-left: 24px;
  height: 51px;
}

#my-header > div.inner > .section a {
  color: var(--light-link-color);
}

#my-header > div.inner > .section a:hover {
  color: var(--light-link-hover-color);
}

#my-header > div.inner .section .content {
  display: flex;
}

#my-header > div.inner .section .content.notifications {
  width: 51px;
  height: 51px;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
}

#my-header > div.inner .section .content.notifications .badge {
  position: absolute;
  cursor: pointer;
  right: 0;
  top: 0;
  background-color: rgba(190, 0, 0, 0.5);
  color: white;
  border: rgba(255, 255, 255, 0.5) 1px solid;
  padding: 7px 3px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 50%;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  filter: drop-shadow(0px 2px 4px black);
}

#my-header > div.inner .section .content.notifications .badge .badge-content {
  display: inline-block;
}

#my-header > div.inner .section .content #notifications-top {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  /* background-color: rgba(0, 0, 0, 0.4); */
  border-radius: 50%;
}

#my-header > div.inner .section .content #notifications-top svg {
  width: 24px;
  height: 24px;
  color: var(--light-text-color);
}

#my-header > div.inner .section .content #notifications-top:hover svg {
  color: white;
}

#my-profile-image-top {
  border-radius: 50%;
  padding: 2px 2px 3px 4px;
  height: 48px;
  width: 48px;
}

.large > .currency-text { 
  display: flex;
}

.large > .currency-text > .currency-display-decimal { 
  font-size: 24px;
  color: var(--text-color);
  padding-top: 6px;
}

p.reset-good { 
  line-height: 32px;
  text-align: center;
}

p.reset-good a { 
  color: var(--link-color);
  text-decoration: underline;
}

p.reset-good a:hover {
  text-decoration: none;
}

.details-body .section {
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 32px;
}

.details-body .section-wrap {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-bottom: 48px;
}

.details-body .section-wrap .dark { 
  margin-right: 24px;
  padding: 24px;
}

.details-body #batch-result-details.section-wrap { 
  font-size: 14px;
}

.details-body .section-wrap .date-details { 
  padding: 6px 0; 
  margin-right: 48px;
  color: rgba(255, 255, 255, 0.75);
}

.details-body .section-wrap .date-details a.copy-clipboard { 
  display: block;
}

.details-body .section-wrap .date-details .copy-clipboard svg:last-child { 
  margin-left: 12px;
}

.details-body .section-wrap .date-details.not-processed {
  color: var(--disabled-text-color);
}

.details-body .section-wrap .picked-up-message.picked-up.date-details svg { 
  color: var(--header-green);
}

.details-body .section-wrap .date-details svg {
  margin-right: 6px;
}

.details-body .section-wrap .date-details strong { 
  font-weight: normal;
  color: white;
}

.details-body .section-wrap .date-details.not-processed strong {
  color: var(--disabled-text-color);
}

.details-body .section-wrap .picked-up-message strong {
  font-weight: normal;
  color: var(--header-green);
}

.details-body .section-wrap .picked-up-message.not {
  color: var(--yellow);
}

.details-body .section-wrap .picked-up-message.not.not-processed {
  color: var(--disabled-text-color);
}

.details-body .section24-48 {
  padding: 24px;
  margin-bottom: 48px;
}

.details-body .section16-32 {
  padding: 24px;
  margin-bottom: 32px;
}

.details-body .section16-48 {
  padding: 24px;
  margin-bottom: 48px;
}

#notifications-box { 
  position: absolute;
  top: 86px;
  right: 18px;
  width: 300px;
  min-height: 100px;
}

#notifications-box .arrow-holder { 
  display: flex;
  justify-content: end;
  padding-right: 24px;
}

#notifications-box .arrow-holder .arrow-up {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid rgba(0, 0, 0, 0.65);
}

#notifications-box .content { 
  padding: 16px;
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: 12px;
}

#main-notification-section { 
  cursor: pointer;
}


ul#notifications-item-list { 
  display: block;
  padding: 0;
  margin: 0;
}

ul#notifications-item-list li {
  display: block;
  font-size: 14px;
  border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
}

ul#notifications-item-list li:last-child {
  display: block;
  border-bottom-width: 0;
}

ul#notifications-item-list li a {
  display: flex;
  padding: 12px;
  color: rgba(255, 255, 255, 0.55);
  border-left: transparent solid 6px;
  justify-content: space-between;
  align-items: center;
}

ul#notifications-item-list li a strong { 
  font-weight: normal;
}

ul#notifications-item-list li a strong span.date { 
  display: block;
  font-size: 10px;
}

ul#notifications-item-list li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

ul#notifications-item-list li a:hover svg {
  color: var(--paylani-blue);
}

.paylani-green { 
  color: var(--paylani-green);
}

.dim-border-bottom { 
  border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
}

.dim-border-top {
  border-top: rgba(255, 255, 255, 0.1) solid 1px;
}

.details-body { 
  display: flex;
  position: relative;
  align-items: stretch;
  flex-wrap: nowrap;
  width: available;
  margin-right: 16px;
}

.empty-box {
  display: block;
  text-align: left;
  padding: 32px 48px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: rgba(255, 255, 255, 0.1) solid 1px;
  max-width: 600px;
  line-height: 1.5;
  margin-top: 48px;
  margin-bottom: 32px;
}

.details-body p {
  margin-bottom: 48px;
  line-height: 1.75;
  color: var(--paragraph-text-color);
  max-width: 720px;
  margin-right: 200px;
}

.details-body p strong {
  color: var(--header-green);
}

.details-body p strong.light { 
  font-weight: normal;
}

.details-body p a.light {
  text-decoration: none;
}

.details-body p a.light:hover {
  text-decoration: underline;
}

.details-body p.tips {
  background-color: rgba(0, 0, 0, 0.25);
  border: rgba(255, 255, 255, 0.1) solid 1px;
  padding: 16px;
}

.details-body p a { 
  text-decoration: underline;
}

.details-body .sub-column {
  border-right: rgba(255, 255, 255, 0.1) solid 1px;
  width: 200px;
  min-width: 200px;
  min-height: 100vh;
  background-color: var(--light-bg-color);
  display: block;
  font-size: 13px;
}

.pad {
  padding: 48px 16px 16px 48px;
}

.pad div.large { 
  width: 800px;
}

.pad div.medium {
  width: 600px;
}

.bottom-pad-64 { 
  padding-bottom: 64px !important;
}

.pad.with-menu { 
  margin-left: 208px;
}

#merchant-sub-menu {
  position: fixed;
}

.details-body .sub-column + .pad.x-large {
  flex: 1;
}

.details-body .sub-sub-menu-container .sub-column ul {
  /* This makes sure the double submenu (1st of them) is touching the top */
  margin-top: 0;
}

.details-body .sub-sub-menu-container { 
  width: 58px;
  border-right: var(--border-color) 1px solid;
  overflow: hidden;
}

.details-body .sub-sub-menu-container .sub-column ul { 
  /* */
}

.details-body .sub-sub-menu-container .sub-column ul li.sub-menu-header { 
  opacity: 0.0;
}

.details-body .sub-sub-menu-container .sub-column ul li {
  /* */
  font-size: 0;
  width: 38px;
  overflow: hidden;
  margin-top: 8px;
}

.details-body .sub-sub-menu-container .sub-column ul li svg {
  /* */
  font-size: 14px;
}

.details-body .sub-column ul {
  display: block;
  padding: 12px;
  list-style: none;
}

.details-body .sub-column ul li { 
  display: block;
  border-bottom: var(--light-border-color) solid 1px;
}

.details-body .sub-column ul li.sub-menu-header {
  color: var(--header-green);
  font-weight: bolder;
  padding: 12px 8px 12px 8px;
}

.details-body .sub-column ul li svg { 
  display: inline-block;
  margin-right: 12px;
}

.details-body .sub-column ul li a,
.details-body .sub-column ul li span {
  display: block;
  padding: 12px 8px 12px 8px;
}

.details-body .sub-column ul li a {
  opacity: 0.7;
  border-radius: 8px;
}

.details-body .sub-column ul li.selected a {
  opacity: 1.0;
  font-weight: bold;
  background-color: var(--submenu-item-selected-color);
}

.details-body .sub-column ul li a svg {
  width: 22px;
}

.details-body .sub-column ul li.selected a svg { 
    color: var(--paylani-green-faded);
}

.details-body .sub-column ul li.selected a:hover {
  opacity: 1.0;
  background-color: var(--submenu-item-selected-color);
}

.details-body .sub-column ul li a:hover { 
  opacity: 1.0;
  background-color: rgba(0, 0, 0, 0.05);
}

.details-body #metrics-row {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
}

.details-body div.graph {
  border: rgba(255, 255, 255, 0.1) solid 1px;
  height: 180px;
  display: inline-block;
  border-radius: 12px;
  min-width: 300px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.details-body div.graph.small { 
  min-width: 220px;
  height: 140px;
  margin-right: 20px;
}

.details-body div.graph .content { 
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
}

.details-body div.graph .content .bars {
  display: flex;
  align-items: flex-end;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 80%;
  padding: 12px 0 12px 0;
  position: relative;
  gap: 5px;
}

.details-body div.graph .content .bars .bar-column {
  display: inline-block;
  min-width: 20px;
  max-width: 32px;
  min-height: 2px;
  /* background-color: rgba(255, 0, 0, 0.3); */
  width: 33%;
}

.details-body div.graph.small .content .bars .bar-column {
  min-width: 12px;
}

.details-body div.graph .content .bars .bar-column .bar-value {
  display: block;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  font-size: 11px;
  padding-bottom: 5px;
}

.details-body div.graph.small .content .bars .bar-column .bar-value {
  font-size: 10px;
}

.details-body div.graph .content .bars .bar-column .bar-label {
  display: block;
  font-size: 10px;
  text-align: center;
  padding-top: 5px;
  height: 24px;
  color: rgba(255, 255, 255, 0.4);
}

.details-body div.graph.small .content .bars .bar-column .bar-label { 
  font-size: 10px;
  font-family: 'Arial Narrow', serif;
}

.details-body div.graph .graph-subtitle { 
  display: block;
  font-size: 11px;
  color: rgba(255, 255, 255, 0.55);
  padding-top: 8px;
  padding-right: 12px;
  text-align: right;
}

.details-body div.graph .content .bars .bar-column .bar { 
  display: block;
  background-color: #0f5132;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.details-body div.graph .content h4 {
  position: relative;
}

.details-body div.graph .content h4 .svg {
  position: absolute;
}

.details-body div.graph .content .bar-graph-empty-text { 
  width: 100%;
  height: 80%;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  color: var(--text-color-faded);
}

.details-body div.graph.visa .content h4 .svg { 
  left: -32px;
  top: -6px;
}

.details-body div.graph.mastercard .content h4 .svg {
  left: -32px;
  top: -6px;
}

.details-body div.graph.discover .content h4 .svg {
  left: -2px;
  top: -10px;
  opacity: 0.7;
}

.details-body div.graph.visa .content .bars .bar-column .bar {
  background-color: #AAA9FF88;
}

.details-body div.graph.mastercard .content .bars .bar-column .bar {
  background-color: #EED02D88;
}

.details-body div.graph.discover .content .bars .bar-column .bar {
  background-color: #CE702D88;
}

.details-body #recent-batches { 
    margin-top: 24px;
}

.details-body .box { 
  width: 400px;
  border: rgba(255, 255, 255, 0.1) solid 1px;
  height: 280px;
  display: inline-block;
  margin-left: 24px;
}

.details-body .box:first-child { 
  margin-left: 0;
}

ul.hover-check { 
  
}

ul.hover-check li { 
  cursor: pointer;
  opacity: 0.35;
}

ul.hover-check li.selected,
ul.hover-check li:hover { 
  opacity: 1.0;
}

ul.hover-check li > div { 
  float: right;
  padding: 16px;
}

ul.hover-check li > div svg { 
  width: 32px;
  height: 32px;
  color: var(--text-color-faded);
}

ul.hover-check li:hover > div svg,
ul.hover-check li.selected > div svg {
  color: var(--header-green);
}

div.x-large { 
  width: 100%;
}

label.tabs { 
  display:block;
}

label.tabs span.tab { 
  display: inline-block;
  margin-left: 12px;
  font-size: 14px;
}

label.tabs span.tab a {
  color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 6px 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

label.tabs span.tab.selected a {
}

label.tabs span.tab a:hover { 
}

.code {
  border-radius: 16px;
  font-family: monospace;
  background: var(--code-background-color);
  line-height: 1.5;
  padding: 24px 12px;
  font-size: 13px;
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  overflow-y: auto;
  display: block;
}

.code.failed { 
  color: var(--error-red);
}

table {
  padding: 0;
  margin: 0;
  table-layout: fixed;
  border-collapse: collapse;
}

table tr > td.empty { 
  border-width: 0;
  padding-left: 0;
  padding-right: 0;
}

table.unfixed { 
  table-layout: auto;
}

table th {
  text-align: left;
  padding: 8px;
  font-size: 12px;
  color: var(--header-green);
  font-weight: bolder;
  opacity: 0.8;
}

table th a {
  color: var(--header-green);
  cursor: pointer;
  text-decoration: none;
}

table th a:hover { 
  color: var(--paylani-blue);
}

table th:last-child,
table td:last-child { 
  text-align: right;
}

table th.date,
table td.date {
  width: 192px;
}

table tr td.failed {
  color: var(--failed-line-item-color);
}

table tr.failed td { 
  background-color: #FF00000A;
}

table tr.job-summary-1 td,
table tr.activity-sent td span svg {
  color: var(--text-color);
}

table tr .date svg { 
  margin-left: 8px;
}

table tr.idle td svg {
  color: var(--text-color);
}

table tr.used td svg {
  color: var(--paylani-blue-faded);
}

table tr.active td svg {
  color: var(--header-green);
}

table tr.super-active td svg {
  color: var(--paylani-blue);
}

table tr td.sent { 
  
}

table tr.activity-sent td,
table tr.activity-sent td span svg {
  color: var(--header-green);
}

table tr.activity-warning td,
table tr.activity-warning td span svg {
  color: var(--yellow);
}

table tr.activity-error td,
table tr.activity-error td span svg {
  color: var(--error-red);
}

table tr.activity-sent td span svg {
}

table tr.activity-critical td,
table tr.activity-critical td span svg {
  color: var(--error-red);
}

table tr.job-summary--2 td {
}

table tr td.job-summary--2 {
  color: var(--error-red);
}

table tr td.job-summary--2:last-child {
  font-size: 12px;
}


table tr td.sent svg { 
  margin-right: 8px;
  color: var(--paylani-blue);
}

table tr td.needed a { 
  color: var(--yellow);
}

table tr td.needed svg {
  margin-left: 8px;
  color: var(--yellow);
}

table tr td.small {
  font-size: 12px;
}

table tr td.small, table tr.small td { 
  font-size: 13px;
}

table tr td.tiny {
  font-size: 11px;
  word-wrap: break-word;
}

table tr td.dimmed, table tr td.dimmed a {
  color: rgba(0, 0, 0, 0.5);
}

table tr td.dimmed a:hover { 
}

table td a { 
  
}

table tr.unread td {
  font-weight: bold;
}

table tr.read td,
table tr.read td a {
  font-weight: normal;
}

table tr.read td a:hover { 
}

table tr td:first-child a svg { 
  width: 18px;
}

.table-x-large {

}

.table-large {

}

.table-medium {
  max-width: 1200px;
  min-width: 600px;
  width: 70%;
}

.table-small {
  max-width: 800px;
  min-width: 400px;
  width: 50%;
}

.small-text { 
  font-size: 13px;
}

table.small-cells tr td {
  padding: 8px 8px;
}

.copy-text { 
  cursor: pointer;
}

.copy-text .is-copied { 
  display: none;
  color: var(--header-green);
}

.copy-text .is-copied svg {
  color: var(--header-green);
}

.copy-text.copied .is-copied { 
    display: inline-block;
}

.copy-text.copied .not-copied {
  display: none;
}

.copy-text.has-icon svg { 
  margin-left: 24px;
}

ul.disabled li, ul li.disabled, ul li.disabled svg {
  color: rgba(0, 0, 0, 0.25);
}

.empty-box.middle { 
    display: flex;
    justify-content: center;
    align-items: center;
}

.empty-box svg { 
  margin-left: 12px;
  width: 24px;
  height: 24px;
}

.empty-box a { 
  color: var(--header-green);
}

.empty-box a:hover { 
  color: var(--paylani-green);
  text-decoration: underline;
}

.small-cell, td.number-cell {
  width: 60px;
}

.email-cell {
  width: 230px;
}

.phone-cell {
  width: 150px;
}

span.status-box { 
  display: inline-block;
  border-radius: 6px;
}

span.status-box.pending-approval {
  background-color:  #AAFEA530;
  color: rgba(255, 255, 255, 0.5);
}

span.status-box.approved {
  background-color:  var(--approved-color-faded);
  color: rgba(255, 255, 255, 0.75);
}

span.status-box.enrolled {
  background-color:  #AAFEA550;
  color: white;
}

span.status-box label { 
  display: inline-block;
  padding: 8px 16px;
}

td.type, th.type { 
  width: 150px;
}

td:first-child svg { 
  display: inline-block;
  margin-right: 12px;
}

th.small-cell, td.small-cell {
  width: 60px;
}

.table-x-large th,
.table-large th,
.table-medium th,
.table-small th,
.table-x-large td,
.table-large td,
.table-medium td,
.table-small td { 
  border-bottom: var(--border-color) 1px solid;
  padding: 16px 8px 16px 8px;
}

.details-body.small p,
p.small {
  width: 280px;
}

.details-body.medium p,
p.medium {
  width: 480px;
}

.details-body.large p,
p.large {
  width: 640px;
}

span.separator { 
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  height: available;
  min-height: 12px;
  vertical-align: center;
  align-items: center;
}

.details-body h3,
.details-body h2 { 
  display: flex;
  align-items: center;
  min-height: 48px;
}

.details-body h2 > .panel { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 100%;
}

.details-body h2 > .panel > .span:last-child { 
  
}

/* Back Button */

span.back-button a {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin-right: 12px;
  color: var(--text-color);
  border: var(--light-border-color) solid 1px;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
}

span.back-button a:hover { 
  background-color: var(--light-box-color);
}

span.back-button svg {
  margin-left: 16px;
  margin-right: 16px;
  text-align: center;
}

span.back-button a:hover svg {
}

/* End Back Button */

td.check { 
  
}

td.check svg { 
  margin-right: 12px;
}

.right { 
  float: right;
}

.left { 
  float: left;
}

.details-body h2 span.subtitle {
  display: inline-block;
  color: var(--text-color-faded);
  margin-left: 12px;
  white-space: nowrap;
}

.details-body h2 span.bullet { 
  font-size: 14px;
  display: inline-block;
  margin-left: 12px;
  padding-bottom: 0;
  padding-top: 4px;
}

.details-body h2.nav, .details-body h2.nav a {
  color: var(--text-color-faded);
}

.details-body h2.nav, .details-body h2.nav a:hover { 
  color: var(--text-color);
}

.details-body h2.nav span.subtitle {
  color: var(--text-color);
}

.details-body h2 span.subtitle-end {
  display: inline-block;
  color: var(--link-color);
  margin-left: 12px;
}

.details-body h3 span.subtitle {
  color: var(--link-color);
  margin-left: 16px;
}

.details-body h2 span.subtitle a.copy-clipboard svg:last-child,
.details-body h3 span.subtitle a.copy-clipboard svg:last-child { 
  margin-left: 12px;
}

.details-body h2:hover span.subtitle a.copy-clipboard svg:last-child,
.details-body h3:hover span.subtitle a.copy-clipboard svg:last-child {
  /* */
}

.submenu {
  font-size: 14px;
  margin-bottom: 32px;
}

h1 span.subtitle { 
  margin-right: 8px;
  color: #AAA;
}

.submenu.merchant {
  
}

.submenu a { 
  display: inline-block;
  margin-right: 24px;
  padding-bottom: 8px;
  padding-top: 8px;
  color: var(--link-color);
  border-bottom: transparent 3px solid;
}

.submenu-delimiter { 
  display: inline-block;
  margin-right: 12px;
  margin-left: 12px;
}

.submenu.batch { 
  font-size: 14px;
}

.submenu.batch a {
  margin-right: 0;
}

.submenu a.selected {
  border-bottom-color: var(--paylani-green-faded);
  color: var(--link-color);
}

.submenu a:hover {
  color: var(--link-hover-color);
  border-bottom-color: var(--paylani-green);
}

#batch-results p { 
  margin-top: 32px;
}

.details-dropdown { 
  display: block;
  max-width: 220px;
}

.details-dropdown > div { 
  display: block;
  font-size: 13px;
  padding-bottom: 8px;
}

.details-dropdown > select,
.form.details-dropdown > select { 
  width: 200px;
}

.form { 
  
}

.form .form-group { 
  margin-bottom: 24px;
  display: block;
}

.form .form-header-with-image {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 48px;
}

.form .form-header-with-image > div.image > span:first-child {
  
}

.form .form-header-with-image > div.form-group { 
  display: block;
  width: 100%;
}

.form .form-group .check-box { 
  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
}

.form .form-group .check-box > label {
  display: inline-block;
}

.form .form-group .check-box > label:first-child {
  margin: 0 0 0 16px;
  display: inline-block;
}

.form .form-group .check-box > label:last-child {
  margin: 0 16px 0 0;
  display: inline-block;
}

.form .form-group .check-box > span:last-child { 
}

.form .form-group .check-box > span:last-child svg { 
  margin: 0;
  padding: 0;
}

svg.yes { 
  color: var(--header-green);
}

.multi-form .form { 
  margin-bottom: 50px;
}

.multi-form h4 {
  font-size: 14px;
  color: var(--header-green);
  font-weight: bolder;
}

.form .form-group.double { 
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0 24px;
}

.form .form-group.double.bottom {
  align-items: flex-end;
}

.form .form-group.double div { 
  width: calc(92% - 12px);
}

.form .form-group.triple {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0 24px;
}

.form .form-group.triple div {
  width: calc(92% - 12px);
}

.form .form-group.quadruple {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0 24px;
}

.form .form-group.quadruple div {
  width: calc(25% - 12px);
}

.details-body.small .form .form-group,
.form .form-group.small {
  width: 280px;
}

.details-body.medium .form .form-group,
.form .form-group.medium {
  width: 480px;
}

.details-body.large .form .form-group,
.form .form-group.large {
  width: 640px;
}

.form .form-group label { 
  display: block;
  margin-top: 16px;
  margin-bottom: 12px;
  font-size: 14px;
  opacity: 0.75;
}

.form .form-group.selected *,
.form .form-group.selected svg { 
  color: var(--paylani-blue) !important;
}

.form .form-group.faded * {
  color: var(--text-color-faded) !important;
}

.form .form-group.faded svg {
  color: red !important;
}


.form .form-group label:first-child { 
  margin-top: 0;
}

.form .form-group span.checkbox { 
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.form .form-group span.checkbox > label { 
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
}

.form div.button { 
  padding-top: 24px;
  padding-bottom: 24px;
}

.form div.button.fixed { 
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-top: 1px solid var(--border-color);
  margin-left: 564px;
  background-color: var(--bg-color);
  padding: 24px 0;
  width: 744px;
}

tr.config-row { 

}

tr.config-row.selected td { 
  background-color: var(--light-box-color);
}

tr.config-row td:first-child {
  
}

tr.config-row td:first-child p {
  padding: 0;
  margin: 0;
}

tr.config-row td:first-child strong {
  display: block;
  margin-bottom: 5px;
}

tr.config-row td:first-child strong + span {
  display: block;
  font-size: 12px;
  padding-bottom: 5px;
}

td.config-element select,
td.config-element input[type=text],
td.config-element input[type=number],
.form textarea,
.form .form-group textarea,
.form select,
.form input[type=text],
.form input[type=email],
.form input[type=number],
.form input[type=date],
.form input[type=datetime-local],
.form input[type=phone],
.form input[type=password] {
  width: 100%;
  padding: 12px;
  color: var(--text-color);
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 12px;
  border: var(--input-border-color) 1px solid;
  background-color: transparent;
}

td.config-element input[type=text],
td.config-element input[type=number] { 
  width: 120px;
}

td.config-element select { 
  width: unset;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
  color: unset;
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  -webkit-text-fill-color: unset !important;
}

.form textarea { 
  height: 100px;
  width: 400px;
  min-width: 300px;
  min-height: 80px;
}

.form textarea:focus,
.form select:focus,
.form input:focus {
  outline: rgba(255, 255, 255, 0.4) 1px solid;
}

.form textarea.error,
.form select.error,
.form input.error {
  outline: rgba(255, 0, 0, 0.7) 1px solid;
  background-color: rgba(255, 0, 0, 0.1);
}

.form select.shrinkwrap,
.form input.shrinkwrap,
.form textarea.shrinkwrap { 
  margin-bottom: 0;
}

.form select.shrinkwrap.trend { 
  max-width: 140px;
}

.form input.small {

}

.form input.medium {

}

.form input.large {

}

.form input.stretch { 
  display: block;
}

.round8 {
  border-radius: 8px;
}

.round12 {
  border-radius: 12px;
}

.round16 {
  border-radius: 16px;
}

.round24 {
  border-radius: 24px;
}

.round32 {
  border-radius: 32px;
}

.white {
  background-color: white;
}

.panel-box {
  background-color: var(--light-box-color);
}

.panel-box.round8,
.panel-box.round16,
.panel-box.round12 { 
  border: var(--light-border-color) 1px solid;
}

.darkest {
  background-color: rgba(0, 0, 0, 0.75);
}

.horizontal {
  display: flex;
  align-items: center;
}

.form.horizontal { 
  width: 300px;
}

.virtual-terminal-button { 
  display: inline-block;
}

.virtual-terminal-button > a { 
  display: inline-block;
  padding: 4px 8px;
  border: #CCC 1px solid;
  text-align: center;
  margin-left: 12px;
  border-radius: 6px;
}

.virtual-terminal-button > a > svg {
  margin-right: 0;
}

.virtual-terminal-button > a:hover > svg {
  color: var(--paylani-blue);
}

.label-small {
  font-size: 12px;
}

.label-medium {
  font-size: 14px;
}

.label-large {
  font-size: 16px;
}

.label-x-large {
  font-size: 20px;
}

.label-small.medium {
  min-width: 100px;
}

.dashboard-snapshot { 
  display: flex;
  justify-content: space-between;
  align-items: start;
  align-content: stretch;
  margin-bottom: 24px;
}

.dashboard-snapshot .form.dropdown > div:first-child { 
  font-size: 14px;
  padding-bottom: 8px;
  text-align: right;
}

.rows { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 24px;
  align-items: center;
  align-content: end;
  text-align: right;
}

.rows ul {
  display:block;
  margin:0;
  padding: 0;
}

.rows ul li {
  display: block;
}

.rows ul li:first-child {
  font-weight: bold;
  font-size: 14px;
}

.dashboard-snapshot a { 
  color: var(--text-color);
  text-decoration: none;
}

#home-body .dashboard-snapshot > a {
  min-width: 280px;
}

.dashboard-snapshot a ul li:first-child {
  color: var(--header-blue);
}

.dashboard-snapshot a.rows { 
  display: flex;
}

.dashboard-snapshot a.panel-box:hover { 
  background-color: rgba(0, 0, 0, 0.1);
}

.dashboard-snapshot a.round16 { 
}

.dashboard-snapshot a:hover.round16 { 
}

.dashboard-snapshot .rows {
  padding: 16px;
  margin-right: 24px;
}

.dashboard-snapshot a.rows:last-child {
  margin-right: 0;
}

.dashboard-snapshot .rows .large {
  font-size: 48px;
  font-weight: normal;
  color: var(--darkest);
}

.dashboard-snapshot .rows ul {
  padding: 8px;
}

.dashboard-snapshot .rows ul li {
  margin-top: 4px;
}

.dashboard-snapshot .rows ul li:first-child {
  margin-top: 0;
  padding-bottom: 6px;
}

.dashboard-snapshot .rows ul li > span.ui-span { 
  font-size: 14px;
  font-weight: bold;
  color: var(--header-green);
}

.webhook-item { 
  background-color: var(--light-border-color);
  border-radius: 8px;
  padding: 16px 0 16px 24px;
  margin-bottom: 32px;
}

.details-body.medium .webhook-item p {
  font-size: 14px;
  margin-right: 0;
  width: unset;
}

.webhook-item h4 { 
  margin-bottom: 2px;
  padding-bottom: 0;
  font-size: 16px;
}

.webhook-item p { 
  margin-bottom: 0;
  padding: 0;
}

.webhook-item p i { 
  color: var(--text-color-faded);
}

#reporting-main p {
  max-width: 600px;
}

#automation-settings { 
  padding: 24px;
  border-radius: 16px;
  background-color: var(--light-box-color);
  border: var(--light-border-color) 1px solid;
}

#automation-settings ul.ul li span { 
  display: inline-block;
}

#automation-settings ul.ul li span:first-child {
  padding-right: 12px;
  text-align: right;
  align-content: end;
  width: 256px;
}

#automation-settings ul.ul li span:last-child select { 
  width: 280px;
}

.date-activity {
  display: block;
  margin-bottom: 12px;
  padding-bottom: 12px;
}

.date-activity > span {
  display: inline-block;
  margin-right: 24px;
  font-size: 15px;
  color: var(--text-color);
  border-radius: 6px;
  background-color: var(--light-box-color);
  border: var(--light-border-color) 1px solid;
  padding: 12px 16px;
}

td.inactive {
  color: var(--error-red);
}

.date-activity > span.status-0 {
  color: var(--error-red);
}

.date-activity > span.status-0 svg {
  color: var(--error-red);
}

.date-activity span svg {
  margin-right: 8px;
  color: var(--header-green);
}

.test-mode { 
  display: inline-block;
  border-radius: 5px;
  background-color: var(--bg-color);
  color: var(--header-green);
  border: var(--header-green) 1px solid;
  padding: 8px 16px;
  font-size: 14px;
}

.test-mode.live { 
  opacity: 0;
}

button.ui-button {
  border-width: 0;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
}

.excel a span.loading-icon label,
.button.ui-button label {
  display: inline-block;
  position: relative;
  height: 16px;
  width: 16px;
  border-radius: 24px;
}

.excel a span.loading-icon label { 
  /*   */
}

.picked-up-message { 
  
}


.green-faded { 
  color: var(--paylani-green-faded);
}

svg.success {
  color: var(--header-green);
}

svg.failed {
  color: var(--error-red);
}

.form button.ui-button span:first-child,
.form button.ui-button span:last-child { 
  display: inline-block;
  width: 32px;
}

.login-panel {
  margin: auto;
  width: 420px;
}

#main-body-login .login-panel {
  padding-top: 120px;
  width: 420px;
}

#main-body-login > .version { 
  margin: 100px auto 0 auto;
  padding-top: 60px;
  font-size: 12px;
  width: 420px;
  border-top: #DDD 1px dashed;
  color: var(--text-color-faded);
  text-align: center;
}

.login-panel h2 {
  display: block;
  text-align: center;
  border-bottom: var(--border-color) 1px solid;
  padding-bottom: 32px;
  margin-bottom: 12px;
}

.login-panel label {
  display: block;
  margin-bottom: 32px;
  margin-top: 32px;
}

.login-panel.form .button button { 
  padding: 16px 48px;
  margin-top: 32px;
}

.login-panel button label {
  margin: 12px 0;
}

.login-panel button.spinning span label { 
  margin: 0;
}

.hover-edit { 
  display: none;
}

.webhook-item h4 { 
  margin-right: 100px;
}

.webhook-item:hover .hover-edit { 
  display: inline-block;
}

.webhook-item.show:hover .hover-edit { 
  display: none;
}

.webhook-item .webhook-url { 
  display: none;
}

.webhook-item.show .webhook-url { 
  display: block;
  margin-right: 32px;
}

.webhook-item .webhook-url label {
  font-size: 14px;
  padding-bottom: 12px;
  margin-top: 24px;
  display: block;
}

.webhook-url-inputs { 
  display: flex;
  flex-wrap: nowrap;
  gap: 0 24px;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 12px;
}

.form .webhook-url-inputs button.ui-button { 
  padding: 0;
  margin: 0;
}

.form .webhook-url-inputs input[type=text] { 
  margin: 0;
  background-color: #FFFFFF88;
}

.float-right { 
  float: right;
  z-index: 100;
}

.float-right a { 
  display: inline-block;
}

.float-right a:hover { 
  /*  */
}

.float-left { 
  float: left;
}

.float-right.padded { 
  margin-right: 16px;
}

.webhook-item .hover-edit a { 
  font-size: 14px;
  color: var(--text-color);
}

.webhook-item .hover-edit a:hover {
  font-size: 14px;
}

.form .webhook-item button { 
  padding: 10px 32px;
}

.form .webhook-item button.pressed { 
  opacity: 0.4;
}

button.ui-action-button { 
  padding-left: 12px;
  padding-right: 12px;
}

button.ui-action-button div { 
  display: flex;
  justify-content: center;
  align-items: center;
}

button.ui-action-button div span { 
  padding-top: 0;
}

button.ui-action-button div > span:first-child {
  width: 16px;
  margin-right: 8px;
}

button.ui-action-button div > span:last-child {
  width: 16px;
  min-height: 20px;
  margin-left: 8px;
}

.refresh-button > a label.spinner.spinning,
.spinner .spinning > label,
.spinner.spinning > label,
button.spinner.spinning span > label,
.excel a.spinning span.loading-icon > label,
.form button.ui-button.spinning span > label {
  border: 3px solid #FFFFFFAA;
  border-top: 3px solid #0f5132;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  animation: spin 0.4s linear infinite;
}

a > span.spinner.spinning span label {
  border: 3px solid #FFFFFFAA;
  border-top: 3px solid #0f5132;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  animation: spin 0.4s linear infinite;
}

.refresh-button > a { 
  white-space: nowrap;
}

.refresh-button > a label.spinner.spinning {
  width: 12px;
  height: 12px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Everything below here references components. Mess with this at your own risk */

span.ui-span {
  display: inline-block;
  padding: 0 !important;
  margin:0 !important;
}

span.ui-span a { 
  display: inline-block !important;
  padding: 0 !important;
  margin:0 !important;
}

ul.action-options {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;
}

ul.action-options > li > div {
  display: block;
  border: var(--light-border-color) 1px solid;
  margin-bottom: 32px;
  border-radius: 8px;
  background-color: var(--light-box-color);
  padding: 24px 24px 16px 24px;
  max-width: 800px;
}

ul.action-options > li > div h4 {
  margin-bottom: 0;
  font-size: 18px;
}

ul.action-options > li > div h4 svg {
  margin-right: 8px;
  color: var(--header-green);
}

ul.action-options > li > div p {
  font-size: 13px;
  color: var(--darkest);
  padding-bottom: 0;
  margin-bottom: 0;
  opacity: 0.7;
}

ul.action-options > li > div:hover p {
  opacity: 1.0;
}

ul.action-options > li > div.with-buttons {
}

ul.action-options > li > div div.buttons {
  margin-top: 24px;
  padding-top: 12px;
  border-top: rgba(255, 255, 255, 0.1) 1px dashed;
}

ul.action-options > li > div div.buttons a {
  display: inline-block;
  border-radius: 8px;
  padding: 8px 16px;
  background-color: var(--action-button-color);
  border: rgba(255, 255, 255, 0.1) 1px solid;
  margin-right: 24px;
}

ul.action-options > li > div div.buttons a svg {
  margin-right: 8px;
}

ul.action-options > li > div div.buttons a:hover {
  background-color: var(--paylani-blue);
  color: var(--bg-color);
}

ul.action-options > li > div div.buttons a:hover svg {
  color: var(--bg-color);
}

ul.action-options > li > div div.buttons a.merchant-item { 
  margin-bottom: 8px;
  margin-right: 8px;
  padding: 6px 12px;
  font-size: 12px;
}

ul.action-options > li > div div.buttons a.merchant-item svg { 
  color: var(--paylani-blue-faded);
}

#settings-main {
  display: flex;
  align-content: start;
  align-items: start;
  justify-content: flex-start;
  margin-bottom: 32px;
  flex-wrap: wrap;
}

.details-body #settings-main .box {
  width: 240px;
  border: rgba(255, 255, 255, 0.1) solid 1px;
  height: 220px;
  display: inline-block;
  margin: 0 42px 42px 0;
}

.details-body #settings-main .box div,
.details-body #settings-main .box div p {
  text-align: center;
}

.details-body #settings-main .box div h4 { 
  color: var(--header-green);
}

.details-body #settings-main .box:hover div h4 { 
  color: var(--paylani-green);
}

.details-body #settings-main .box div p {
  text-align: center;
  margin-right: unset;
  font-size: 14px;
  padding: 2px 12px;
}

.details-body #settings-main .box:hover div p { 
}

.details-body #settings-main .box > div { 
  padding: 24px;
}

.details-body #settings-main .box > div svg { 
  text-align: center;
  color: var(--link-color);
  height: 64px;
}

.details-body #settings-main .box:hover > div svg {
  color: var(--link-hover-color);
}

ul.action-options li ol {
  padding-left: 0;
}

ul.action-options li ol li { 
  background-color: white;
  margin-bottom: 6px;
  margin-top: 0;
  padding-bottom: 8px;
  padding-top: 12px;
}

.details-body p.agenda strong { 
  color: unset;
}

.details-body p strong.h4 { 
  display: block;
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 6px;
  color: var(--header-green);
}

strong.update-rate { 
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 5px 12px;
  display: inline-block;
  margin-left: 12px;
  color: var(--yellow);
}

tr.sale-tr { 
  
}

tr.sale-tr td a.full { 
  display: block;
}

.environment-item {
  border-color: #e0e0e0; /* Color */;
}

.environment-item.selected, .environment-item:hover {
  background-color: var(--light-border-color);
}

.environment-item > .environment-item-body > h4 {
  color: var(--header-green);
}

.environment-item > .environment-item-body > h4 > svg {
  color: var(--header-green);
}

.environment-item > div:last-child svg {
  color: transparent;
}

.environment-item:hover > div:last-child svg {
  color: var(--border-color);
}

.environment-item.selected > div:last-child svg {
  color: green;
}
