
#reporting-snapshot { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: start;
    flex-wrap: nowrap;
}

table#card-brand-list { 
    width: 100%;
}

table#card-brand-list tr th,
table#card-brand-list tr td {
    width: 25%;
}

table#card-brand-list tr th:first-child,
table#card-brand-list tr td:first-child { 
    width: 50%;
}

.pie-chart,
.pie-chart text { 
    color: white;
    display: inline-block;
    margin: 12px;
}

.pie-chart text {
    fill: rgba(255, 255, 255, 0.55);
    font-weight: bold;
    font-size: 11px;
}

.pie-chart .american-express text {
    background-color: red;
}

.pies { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: start;
    margin-left: 32px;
}

.pies div {
    margin-left: 32px;
    padding: 12px;
}

.bar-chart-graph-footer,
.card-brand-line-graph-footer { 
    font-size: 13px;
    margin-top: 12px;
    text-align: right;
    color: rgba(255, 255, 255, 0.75);
}

.bar-chart-graph-container,
.card-brand-graph-container { 
    border-radius: 12px;
    height: 272px;
    width: 100%;
    display: block;
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.bar-chart-graph-container canvas { 
    height: 272px;
}

.bar-chart-graph h3,
.card-brand-line-graph h3 {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 48px;
}

#reporting-main .card-brand-line-graph h3 { 
    margin-top: 48px;
}

h3.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

h3.controls label { 
    display: inline-block;
    margin-right: 24px;
}

h3 span.line-controls { 
    display: inline-block;
    margin-left: 12px;
    font-size: 12px;
}

h3 span.line-controls.provider {
    align-self: end;
}

h3 span#home-line-controls a {
    display: inline-block;
    margin-right: 12px;
    padding: 8px 16px;
    /* background-color: rgba(255, 255, 255, 0.05); */
    border-radius: 3px;
    color: var(--text-color);
}

h3 span#home-line-controls a.selected {
    background-color: var(--light-box-color);
    color: var(--paylani-blue);
}

h3 span#home-line-controls a:hover {
    background-color: var(--light-box-color);
    color: var(--header-green);
}

#line-pie-graph-container { 
    display: flex;
    align-content: start;
    align-items: start;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 32px;
}

#line-pie-graph-container #line-graph-container {
    display: inline-block;
    width: 70%;
}

#line-pie-graph-container #pie-graph-container {
    display: inline-block;
    width: 30%;
    padding-top: 12px;
}

#line-pie-graph-container #pie-graph-container .round24 { 
    padding: 16px;
    display: inline-block;
}

table tfoot.total { 
    background-color: #4fA13222;
}

table tfoot td { 
    font-weight: bold;
}

table.big-report { 
    
}

table.big-report td {
    white-space: nowrap;
    overflow: hidden;
}
.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
    margin-top: 24px !important;
}

.report-expanded-menu { 
    margin-top: 4px;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 12px 12px;
    font-size: 12px;
    border-bottom: var(--border-color) 1px dashed;
}

.report-expanded-menu > a { 
    display: inline-block;
    border-radius: 8px;
    padding: 8px 16px;
    background-color: var(--paylani-blue);
    color: var(--bg-color);
}

.report-selector-panel.report-expanded-menu .item > a { 
    margin-right: 32px;
}

.MuiPaper-root .report-expanded-menu h3 { 
    font-size: 20px;
    margin-bottom: 0 !important;
}

.report-selector-panel { 
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    white-space: nowrap;
    align-items: center;
    
    gap: 16px;
    margin-bottom: 24px;
}

.report-selector-panel .date-range-selector { 
    margin-bottom: 0;
}

.report-selector-panel .date-range-selector input[type="date"] { 
    width: 150px;
    min-width: 150px;
}

.report-selector-panel .item a { 
    padding: 8px 12px;
    display: inline-block;
    border-radius: 6px;
    border: var(--light-border-color) 1px solid;
}

.report-selector-panel .item a:hover { 
    background-color: var(--link-color);
    color: var(--bg-color);
}

.report-selector-panel .item.spacer { 
    width: 100%;
}

.report-selector-panel .item a:hover svg { 
    fill: var(--bg-color);
    color: var(--bg-color);
}

.report-selector-panel .select-columns { 
    display: inline-block;
    font-size: 13px;
}

.report-selector-panel .item a svg { 
    color: var(--link-color);
    margin-right: 6px;
}

a.export-item { 
    display: inline-block;
    padding: 5px;
    background-color: white;
    border-radius: 3px;
    margin: 8px 4px;
}

@media (max-width:800px) {
    .payment-type {
        background-color: red;
    }
}
