
.details-body .merchant-user-details { 
    display: flex;
    justify-content: flex-start;
}

.details-body .merchant-user-details > div.profile-image {
    margin-right: 24px;
    min-width: 140px;
}

.details-body .merchant-user-details > div.profile-image img {
    height: 128px;
    width: 128px;
    border: var(--light-border-color) 2px solid;
    object-fit: cover;
    border-radius: 50%;
}

.details-body .merchant-user-details > div.profile-image > span {
    max-height: 120px;
    max-width: 120px;
    min-width: 120px;
    min-height: 120px;
    overflow: clip;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border: var(--light-border-color) 8px solid;
    object-fit: cover;
    border-radius: 50%;
    background-color: #AAAAAA20;
}

.details-body .merchant-user-details > div.profile-image > span > svg {
    color: var(--paylani-blue-faded);
    max-height: var(--image-size);
    max-width: var(--image-size);
    min-height: var(--image-size);
    min-width: var(--image-size);
}

#merchant-users table tr > td {
    position: relative;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
}

#merchant-users table tr:hover > td {
    overflow: unset;
}

#merchant-users table tr:hover > td.no-wrap > span { 
    background-color: var(--bg-color);
    display: block;
    position: absolute;
    top: 16px;
    bottom: 0;
    left: 8px;
    z-index: 12;
}

.merchant-ownership {
    gap: 32px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    align-content: start;
    padding-bottom: 16px;
    margin-bottom: 32px;
    border-bottom: var(--light-border-color) 1px dashed;
}

.details-body .merchant-ownership p { 
    margin-top: 0;
    margin-right: 24px;
    flex: 1;
}

.config-input { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 16px;
}

.config-input > span:first-child {
    flex: 2;
}

.config-input > span:last-child { 
    width: 120px;
}

.config-input.form  input[type="text"] { 
    margin-bottom: 0;
    display: inline-block;
}

td.config-element .config-dropdown {
    
}

td.config-element .config-dropdown select { 
}

td.config-element .config-dropdown.working select,
td.config-element .config-dropdown.working select:focus {
    color: var(--paylani-blue);
}

td.config-element .config-dropdown.success select,
td.config-element .config-dropdown.success select:focus { 
    color: var(--header-green);
}

tr.config-row > td:last-child { 
    text-align: right;
}

.tip-type-selector { 
    border-radius: 6px;
    border: var(--light-border-color) 1px solid;
    overflow: clip;
    
    display: flex;
    justify-content: stretch;
    margin-bottom: 24px;
}

.tip-type-selector > a {
    flex: 1;
    display: inline-block;
    text-align: center;
    padding: 8px 0;
}

.tip-type-selector > a.selected,
.tip-type-selector > a.selected:hover { 
    background-color: var(--paylani-blue);
    color: var(--bg-color);
}

.tip-type-selector > a:hover { 
    background-color: var(--paylani-blue-faded);
    color: var(--bg-color);
}

.merchant-tips {
    width: 420px;
}

.merchant-tips .button { 
    margin-top: 16px;
    margin-bottom: 48px;
}

.merchant-tips.none ul { 
    display: none;
}

ul.tips {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul.tips > li { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 16px;
    padding: 8px 0;
    border-bottom: var(--light-border-color) 1px solid;
}

ul.tips > li > span {
    position: relative;
    display: inline-block;
    flex: 1;
}

ul.tips > li > span:last-child { 
    font-size: 14px;
    text-align: right;
    flex: 2;
}

ul.tips > li > span > span.symbol { 
    position: absolute;
    font-size: 18px;
    top: 9px;
    color: var(--paylani-blue);
}

ul.tips > li > span > span.symbol.dollars { 
    left: 8px;
}

ul.tips > li > span > span.symbol.percent {
    right: 8px;
}

ul.tips > li > span:last-child > a.remove {
    
}

ul.tips > li > span > input[type="number"],
ul.tips > li > span > input[type="text"] { 
    margin-bottom: 0;
    padding-left: 32px;
}

div.merchant-field-item { 
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    gap: 16px;
    margin-bottom: 16px;
    
    border-radius: 8px;
    border: var(--border-color) 1px solid;
    background-color: var(--light-border-color);
    padding: 12px;
}

div.merchant-field-item > span { 
    display: inline-block;
}

table.merchant-fields tr > td:first-child,
table.merchant-fields tr > th:first-child { 
    width: 35%;
}
div.sales-fields-form { 
    min-width: 480px;
}

.form.sales-fields-form .form-group { 
    margin-bottom: 8px;
}

div.form.sales-fields-form div.form-group > div.check-box > label { 
    width: 140px;
    text-align: right;
    margin-right: 12px;
    padding-bottom: 6px;
}

div.configuration-list { 
    max-width: 800px;
}